import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

@NgModule({
    declarations: [
        PrivacyPolicyComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
    ],
    providers: [
    ],
})
export class PrivacyPolicyModule { }
