import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HttpRequestService } from './services/http-request.service';

@NgModule({
    declarations: [
        NavbarComponent,
        FooterComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
    ],
    exports: [
        NavbarComponent,
        FooterComponent,
    ],
    providers: [
        HttpRequestService,
    ]
})
export class SharedModule { }
