import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/components/home/home.component';
import { ServicesComponent } from './services/components/services/services.component';
import { WhyChooseUsComponent } from './why-choose-us/components/why-choose-us/why-choose-us.component';
import { ContactUsComponent } from './contact-us/components/contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/components/privacy-policy/privacy-policy.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'services',
        component: ServicesComponent
    },
    {
        path: 'why-choose-us',
        component: WhyChooseUsComponent
    },
    {
        path: 'contact-us',
        component: ContactUsComponent
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
