import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';

import { HttpRequestService } from '../../../shared/services/http-request.service';

@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html'
})
export class ContactUsComponent implements OnInit {
    public contactUsForm: FormGroup;
    public lastStatus: any = {};

    constructor(
        private fb: FormBuilder,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private httpRequest: HttpRequestService,
    ) {
    }

    public ngOnInit() {
        this.contactUsForm = this.fb.group({
            name: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            message: ['', [Validators.required]],
            privacyCheckbox: ['', [Validators.required]]
        });
    }

    public async onSubmit() {
        try {
            const token = await this.getRecaptchaToken();

            await this.sendMessage(token);
        } catch (err) {
            console.error(err);
        }
    }

    get name() {
        return this.contactUsForm.get('name');
    }

    get email() {
        return this.contactUsForm.get('email');
    }

    get message() {
        return this.contactUsForm.get('message');
    }

    get privacyCheckbox() {
        return this.contactUsForm.get('privacyCheckbox');
    }

    private async getRecaptchaToken(): Promise<string> {
        return this.reCaptchaV3Service.execute('contactUs').toPromise();
    }

    private async sendMessage(token: string): Promise<void> {
        const formData = Object.assign({}, this.contactUsForm.value);

        try {
            const result: any = await this.httpRequest.sendContactUs(formData, token).toPromise();

            this.lastStatus.message = result.message;
        } catch (err) {
            const errorMessage = (err && err.responseJSON && err.responseJSON.message)
                ? err.responseJSON.message
                : 'An error occurred';

            this.lastStatus.message = 'An error occurred';
        }

        this.contactUsForm.reset();
    }
}
