import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HomeModule } from './home/home.module';
import { ServicesModule } from './services/services.module';
import { WhyChooseUsModule } from './why-choose-us/why-choose-us.module';
import { ContactUsModule } from './contact-us/contact-us.module';
import { PrivacyPolicyModule } from './privacy-policy/privacy-policy.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CommonModule,
        RecaptchaV3Module,
        SharedModule,
        HomeModule,
        ServicesModule,
        WhyChooseUsModule,
        ContactUsModule,
        PrivacyPolicyModule
    ],
    providers: [
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6LfwMq4UAAAAALPK4JXyNaULgHxvEMIJh7mD1ZCe',
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
