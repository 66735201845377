import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class HttpRequestService {
    constructor(private http: HttpClient) {
    }

    public sendContactUs(formData: any, recaptchaToken: string) {
        const { name, email, message } = formData;

        return this.http.post('/contact-us/submit', {
            name,
            email,
            message,
            recaptchaToken
        });
    }
}
